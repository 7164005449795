<template>
  <el-dialog title="创建直播间" :visible.sync="openDialog" width="270px" class="dialog-vertical" append-to-body>
    <el-image class="qr-code" :src="qrCode" fit="cover"></el-image>
    <p>请使用微信扫码</p>
  </el-dialog>
</template>

<script>
export default {
  name: "AddLiveDialog",
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      qrCode: require("../static/images/add-live-code.png"),
    };
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("closeDialog", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.qr-code {
  width: 180px;
  height: 180px;
}
.dialog-vertical {
  ::v-deep.el-dialog__body {
    text-align: center;
  }
}
</style>