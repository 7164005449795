<!--
 * @Author: dongjia
 * @Date: 2021-06-23 11:50:59
 * @LastEditTime: 2021-10-13 14:48:02
 * @LastEditors: aleaner
 * @Description: 更新直播间数据弹窗 
 * @FilePath: \saas-admin-vue\src\modules\wechat-app\components\UpdateLiveListDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="更新直播间数据" :visible.sync="openDialog" width="440px" class="dialog-vertical"
    @close="cancel('editForm')">
    <el-form size="medium" :model="_data" label-position="right" ref="editForm">
      <el-form-item style="margin-bottom: 0px" label="" prop="page">
        更新第 <el-input v-model.number="page" placeholder="请输入"></el-input> 页的 20 条数据
      </el-form-item>
      <div class="form-tip">
        <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC"></span>
        <span>以 20 条数据为 1 页，填写第 1 页或不填写时将更新最新的 20 条数据</span>
      </div>
    </el-form>
    <span slot="footer">
      <el-button size="small" @click="openDialog = false">取消</el-button>
      <el-button size="small" type="primary" @click="submit('editForm')" :loading="saveLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { newLiveList } from "../api/live-list.js";

import SingleMediaWall from "@/modules/common/components/SingleMediaWall.vue";
import DefaultImageSelector from "../../common/components/DefaultImageSelector.vue";
export default {
  components: { SingleMediaWall, DefaultImageSelector },
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      saveLoading: false,
      page: 1,
    };
  },
  methods: {
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          const data = { page: this.page || 1 };
          newLiveList(data)
            .then((res) => {
              this.$message.success(res.msg);
              this.updateList();
              this.openDialog = false;
              this.saveLoading = false;
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.$refs[formName].resetFields();
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-vertical {
  ::v-deep.el-form-item__label {
    padding-bottom: 0px;
  }

  ::v-deep.el-form-item__content {
    display: flex;
    .el-input {
      width: 70px;
      margin: 0 10px;
    }
  }
}

.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }
}
</style>
