<template>
  <el-dialog title="回放视频" :visible.sync="openDialog" width="860px" class="dialog-vertical" append-to-body>
    <el-table v-loading="loading" :data="tbody" class="thead-light" stripe style="width: 100%"
      @sort-change="sortChange">
      <!-- 操作 -->
      <!-- <el-table-column label="操作" width="90" fixed="right">
        <template slot-scope="scope">
          <el-button size="small" @click="downloadVideo(scope.row)" type="text">
            下载视频
          </el-button>
        </template>
      </el-table-column> -->
      <template v-for="(th, index) in thead">
        <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
          :min-width="th.minWidth || ''" :key="index" :sortable="th.sortable">
          <template slot-scope="scope">
            <!-- 直播推流地址 -->
            <div class="url-view" v-if="th.prop === 'media_url'">
              <span class="text-overflow">{{ scope.row[th.prop] | placeholder }}</span>
              <span v-if="scope.row[th.prop]" class="copy" @click="copyUrl(scope.row[th.prop])">复制</span>
            </div>
            <!-- 设为主视频
            <el-radio v-else-if="th.prop === 'is_selected'" v-model="isSelected" :label="scope.row.id"
              @change="handleSelect">{{""}}</el-radio> -->
            <!-- 普通 -->
            <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
    <span slot="footer">
      <el-button size="small" @click="openDialog = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { reviewkList, setMainVideo } from "../api/live-list";
import Pagination from "@/base/components/Default/Pagination";
import download from "@/base/utils/download";
import { copyText } from "@/base/utils/tool";

export default {
  components: { Pagination },
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    roomID: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      tbody: [],
      pageData: {},
      isSelected: 0,
      thead: [
        {
          label: "视频链接",
          prop: "media_url",
          minWidth: 250,
        },
        {
          label: "生成时间",
          prop: "create_time",
          minWidth: 160,
          sortable: true,
        },
        {
          label: "过期时间",
          prop: "expire_time",
          minWidth: 160,
          sortable: true,
        },
        // { label: "设为主视频", prop: "is_selected", minWidth: 100 },
      ],
      //筛选对象
      filterForm: {
        room_id: 0,
        page_size: 15,
      },
    };
  },
  watch: {
    openDialog(val) {
      this.filterForm.room_id = this.roomID;
      this.getDetailList({ ...this.filterForm });
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("closeDialog", value);
      },
    },
  },
  methods: {
    // 获取列表
    getDetailList(pageData) {
      this.pageData = {};
      this.loading = true;
      reviewkList(pageData)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          const selecteData = data.data.find((item) => item.is_selected === 1);
          this.isSelected = selecteData ? selecteData.id : 0;
          this.pageData = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 下载视频
    downloadVideo(row) {
      console.log(row);
      this.$message.info("正在开发中...");
      // download({
      //   baseUrl: "",
      //   url: row.media_url,
      //   method: "post",
      //   data: {},
      // });
      // this.$axios({
      //   method: "get",
      //   url: row.media_url,
      //   // 必须显式指明响应类型是一个Blob对象，这样生成二进制的数据，才能通过window.URL.createObjectURL进行创建成功
      //   responseType: "blob",
      // }).then((res) => {
      //   if (!res) {
      //     return;
      //   }
      //   console.log("res", res);
      //   aElementDownload(res.data, row.name);
      // 将lob对象转换为域名结合式的url
      // let blobUrl = window.URL.createObjectURL(res.data)
      // let link = document.createElement('a')
      // document.body.appendChild(link)
      // link.style.display = 'none'
      // link.href = blobUrl
      // 设置a标签的下载属性，设置文件名及格式，后缀名最好让后端在数据格式中返回
      // link.download = '下载文件.csv'
      // 自触发click事件
      // link.click()
      // document.body.removeChild(link)
      // window.URL.revokeObjectURL(blobUrl);
      // });
    },
    // 选为主视频
    // handleSelect(val) {
    //   this.loading = true;
    //   setMainVideo({ id: val })
    //     .then((res) => {
    //       this.$message.success(res.msg);
    //       this.getDetailList({
    //         ...this.filterForm,
    //         page: this.pageData.current_page,
    //       });
    //       this.loading = false;
    //     })
    //     .catch((err) => {
    //       this.loading = false;
    //     });
    // },
    // 复制链接
    copyUrl(url) {
      copyText(url).then((valid) => {
        this.$message.success("复制成功");
      });
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getDetailList(this.filterForm);
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const data = { ...this.filterForm, ...e };
      this.getDetailList(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.url-view {
  display: flex;
  align-items: center;
  .copy {
    color: #3479ef;
    cursor: pointer;
    margin-left: 10px;
  }
  .text-overflow {
    display: inline-block;
    width: 210px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.dialog-vertical {
  ::v-deep.el-dialog__body {
    text-align: center;
  }
}
</style>