<template>
  <el-dialog title="上传封面图" :visible.sync="openDialog" width="400px" class="dialog-vertical"
    @close="cancel('editForm')" @open="dialogOpen">
    <el-form v-if="editForm" size="medium" :model="editForm" :rules="rules" label-position="right"
      label-width="80px" ref="editForm">
      <!-- <el-form-item label="海报图：" prop="poster_image">
        <SingleMediaWall v-model="editForm.poster_image" :width="214" :height="119">
          <p slot="info" class="img-tips">
            建议尺寸为670*376
          </p>
        </SingleMediaWall>
        <el-input v-show="false" v-model="editForm.poster_image"></el-input>
      </el-form-item> -->
      <el-form-item style="margin-bottom: 0px" label="封面图：" prop="material_image">
        <DefaultImageSelector :DefaultImage="DefaultImage" v-model="editForm.material_image" :width="214"
          :height="119">
          <div slot="info" class="img-tips">
            <p>提示：</p>
            <p>1、尺寸默认为：424*234</p>
            <p>2、有使用“页面装修功能”时，具体尺寸根据所选中的模版尺寸进行上传</p>
          </div>
        </DefaultImageSelector>
        <el-input v-show="false" v-model="editForm.material_image"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="small" @click="openDialog = false">取消</el-button>
      <el-button size="small" type="primary" @click="submit('editForm')" :loading="saveLoading">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { uploadImg } from "../api/live-list.js";
import SingleMediaWall from "@/modules/common/components/SingleMediaWall.vue";
import DefaultImageSelector from "../../common/components/DefaultImageSelector.vue";
export default {
  components: { SingleMediaWall, DefaultImageSelector },
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    Form: {
      type: Object,
      default: () => {
        return {
          id: "",
          material_image:
            window.serverConfig.VUE_APP_ADMINURL +
            "/blessing/images/admin/blessing2.png",
          poster_image: "",
        };
      },
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      title: "",
      saveLoading: false,
      editForm: null,
      DefaultImage:
        window.serverConfig.VUE_APP_ADMINURL +
        "/blessing/images/admin/blessing2.png",
      rules: {
        material_image: [
          { required: true, message: "请上传封面图", trigger: "change" },
        ],
        poster_image: [
          { required: true, message: "请上传海报图", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    dialogOpen() {
      this.Form.material_image =
        this.Form.material_image ||
        window.serverConfig.VUE_APP_ADMINURL +
          "/blessing/images/admin/blessing2.png";
      this.editForm = { ...this.editForm, ...this.Form };
    },
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          const { material_image, poster_image, id } = this.editForm;
          const data = { material_image, poster_image, id };
          uploadImg(data)
            .then((res) => {
              this.$message.success(res.msg);
              this.updateList();
              this.openDialog = false;
              this.saveLoading = false;
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.$refs[formName].resetFields();
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-vertical {
  ::v-deep.el-form-item__label {
    padding-bottom: 0px;
  }
}

.img-tips {
  // text-align: center;
  margin-top: 10px;
  font-size: 12px;
  color: #c0c4cc;
  line-height: 24px;
}
</style>
