<template>
  <div class="list-page" v-loading="loading">
    <div class="top-btn" v-if="!isOpen">
      <el-button type="primary" size="small" :loading="updateLoading" @click="handleOpenLive">开通直播
      </el-button>
    </div>
    <div class="top-btn" v-else>
      <el-button type="danger" size="small" :loading="updateLoading" @click="handleCloseLive">关闭直播
      </el-button>
      <el-button type="primary" size="small" :loading="updateLoading" @click="updateLiveList">更新直播间数据
      </el-button>
      <!-- <el-button type="primary" size="small" :loading="updateLoading" @click="updateReviewList">更新回放数据
      </el-button> -->
    </div>
    <div v-if="!isOpen">
      <div class="live-tips">
        <div class="tips-title">
          <img style="width: 28px;height:28px;margin-right: 5px"
            src="@/modules/system-admin/assets/images/icon-title.png"> 温馨提示
        </div>
        <div class="tips-content">
          <div class="item-tip">开通前，请先前往 <a href="https://mp.weixin.qq.com/" target="_blank">微信公众平台</a>
            ，登录小程序开通直播功能 <a href="https://www.yuque.com/ax101w/yz6xgk/qr0s10" target="_blank">查看小程序开通直播指引</a>
          </div>
        </div>
      </div>
      <div class="modular" style="margin-top: 15px">
        <p class="modular-title" style="margin-bottom: 32px;">效果预览</p>
        <div class="modular-content">
          <div class="preview-area flex">
            <div class="img-box" v-for="(img, index) in livePreviewImages" :key="livePreviewImages[index]">
              <el-image class="img cursor-pointer" :src="img" @click="$previewImage(livePreviewImages, index)" fit="contain" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isOpen" class="live-tips">
      <div class="tips-title">
        <img style="width: 28px;height:28px;margin-right: 5px"
          src="@/modules/system-admin/assets/images/icon-title.png"> 温馨提示
      </div>
      <div class="tips-content">
        <div class="item-tip"><span style="margin-right: 10px">·</span>直播列表数据仅作为展示参考，具体详情数据请前往 <a
            href="https://mp.weixin.qq.com/" target="_blank">微信公众平台</a> ，登录小程序账号查看</div>
        <div class="item-tip"><span
            style="margin-right: 10px">·</span>点击“更新直播间数据”、“更新回放数据”按钮可以获取微信小程序最新的直播数据、回放数据
        </div>
        <div class="item-tip"><span style="margin-right: 10px">·</span>尽量将每条直播数据都上传封面图，以免影响在微信小程序页面上展示效果</div>
        <div class="item-tip"><span style="margin-right: 10px">·</span>需要创建直播间时，点击“新增按钮”使用微信扫码即可</div>
        <div v-if="paramsData" class="item-tip"><span style="margin-right: 10px">·</span>开通后，前往 <router-link
            :to="{ name: 'AppDesignPage', params: {platform: paramsData.platform,id: paramsData.id}}"
            target="_blank">页面装修</router-link>
          拖拽直播组件查看效果</div>
      </div>
    </div>
    <liveListFilter v-if="isOpen" :filterForm="filterForm" :ids="selectArr" :page="pageData.current_page"
      :uploadFilter="ok"></liveListFilter>
    <div v-if="isOpen" class="list-btn">
      <el-button class="top-btn" type="primary" size="small" @click="openQRCodeDialog = true">创建直播间
      </el-button>
      <el-button type="danger" size="small" @click="handleDel">删除</el-button>
    </div>
    <div v-if="isOpen" class="list-content">
      <el-table :data="tbody" class="thead-light" stripe style="width: 100%"
        @selection-change="handleSelectionChange" @sort-change="sortChange">
        <!-- 勾选-->
        <el-table-column fixed type="selection" width="55"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="160" fixed="right">
          <template slot-scope="scope">
            <el-button size="small" @click="uploadImg(scope.row)" type="text">
              上传封面图
            </el-button>
            <el-divider direction="vertical" />
            <el-dropdown @command="handleClickRow($event, scope.row)">
              <span style="color: #3576ff;font-size:12px" class="el-dropdown-link">更多</span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in scope.row.playback_count? moreHandleList:moreHandleList.filter(el => el.command !== 'reviewVideo')"
                  :key="index" :command="item.command">{{ item.name }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
            :min-width="th.minWidth || ''" :key="index" :sortable="th.sortable">
            <template slot-scope="scope">
              <!-- 显示 -->
              <el-checkbox v-if="['is_recommend','is_show'].includes(th.prop)" :false-label="0"
                :true-label="1" v-model="scope.row[th.prop]" @change="changeStatus(scope.row, th.prop)">
              </el-checkbox>
              <!-- 素材图 -->
              <span
                v-else-if="th.prop === 'is_upload'">{{scope.row.material_image && scope.row.poster_image?'已上传':'未上传'}}</span>
              <!-- 回放视频 -->
              <span v-else-if="th.prop ==='playback_count'">{{scope.row[th.prop]}} 个视频<span
                  class="update-video cursor-pointer" @click="updateReviewList(scope.row.id)">更新</span></span>
              <!-- 直播推流地址 -->
              <div class="url-view" v-else-if="th.prop === 'push_stream_address'">
                <span class="text-overflow">{{ scope.row[th.prop] | placeholder }}</span>
                <span v-if="scope.row[th.prop]" class="copy" @click="copyUrl(scope.row[th.prop])">复制</span>
              </div>
              <!-- 排序 -->
              <PopoverInputEdit v-else-if="th.prop === 'sort'" v-model="scope.row.sort" input-type="number"
                :validator="validator" @confirm="changeStatus(scope.row, th.prop)">
              </PopoverInputEdit>
              <!-- 普通 -->
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination v-if="isOpen" :total="pageData.total" :page="pageData.current_page" :change="changPage" />
    <UpdateLiveListDialog v-model="openUpdateLiveDialog" :updateList="refreshList" />
    <review-video-dialog :roomID="currentId" v-model="openReviewVideoDialog"></review-video-dialog>
    <EditUploadDialog v-model="openEditUploadDialog" :Form="currentForm" :updateList="refreshList" />
    <add-live-dialog v-model="openQRCodeDialog"></add-live-dialog>
    <func-config-dialog v-model="openFuncConfigDialog" :Form="currentForm" :updateList="refreshList">
    </func-config-dialog>
  </div>
</template>

<script>
import PopoverInputEdit from "@/base/components/Popover/PopoverInputEdit";
import Pagination from "@/base/components/Default/Pagination";
import { copyText } from "@/base/utils/tool";
import {
  checkOpenStatus,
  detailList,
  updateShow,
  updateRecommend,
  updateSort,
  Delete,
  newLiveList,
  playbackList,
  openLive,
  closeLive,
  getIndexData,
} from "../api/live-list.js";
import EditUploadDialog from "../components/EditUploadDialog.vue";
import liveListFilter from "../components/LiveListFilter.vue";
import AddLiveDialog from "../components/AddLiveDialog.vue";
import ReviewVideoDialog from "../components/reviewVideoDialog.vue";
import FuncConfigDialog from "../components/FuncConfigDialog.vue";
import UpdateLiveListDialog from "../components/UpdateLiveListDialog.vue";
export default {
  data() {
    return {
      loading: false, //加载
      updateLoading: false, // 拉取数据加载
      thead: [
        { label: "直播间ID", prop: "room_id", minWidth: 80 },
        { label: "直播间名称", prop: "name", minWidth: 150 },
        { label: "主播名称", prop: "anchor_name", minWidth: 110 },
        {
          label: "直播状态",
          prop: "live_status_text",
          minWidth: 110,
        },
        {
          label: "直播间推流地址",
          prop: "push_stream_address",
          minWidth: 250,
        },
        {
          label: "开始时间",
          prop: "start_time",
          minWidth: 160,
          sortable: true,
        },
        {
          label: "计划结束时间",
          prop: "end_time",
          minWidth: 160,
          sortable: true,
        },
        {
          label: "直播类型",
          prop: "live_type_text",
          minWidth: 110,
        },
        {
          label: "封面图",
          prop: "is_upload",
          minWidth: 90,
        },
        // {
        //   label: "回放功能",
        //   prop: "is_upload",
        //   minWidth: 90,
        // },
        {
          label: "回放视频",
          prop: "playback_count",
          minWidth: 150,
        },
        { label: "排序", prop: "sort", minWidth: 60 },
        // { label: "推荐", prop: "is_recommend", minWidth: 60 },
        // { label: "显示", prop: "is_show", minWidth: 60 },
      ],
      //筛选对象
      filterForm: {
        page_size: 15,
        name: "",
        anchor_name: "",
        live_type: -1,
        live_status: -1,
        is_material: -1,
        start_time: -1, //开始时间
        end_time: -1, //结束时间
      },
      moreHandleList: [
        { name: "功能配置", command: "funcConfig" },
        { name: "回放视频", command: "reviewVideo" },
        { name: "删除", command: "deleteRow" },
      ],
      /* 行内编辑数字验证 */
      validator: {
        type: "integer",
        min: 0,
        message: "最小不能低于0的整数",
        trigger: "blur",
      },
      isOpen: 0, // 是否开启小程序直播
      timer: null, // 获取直播状态定时器
      pageData: {},
      tbody: [],
      selectArr: [],
      openType: "",
      currentId: 0,
      currentForm: {},
      pid_list: [], //上级组织列表,
      openQRCodeDialog: false,
      openEditUploadDialog: false,
      openFuncConfigDialog: false,
      openReviewVideoDialog: false,
      openUpdateLiveDialog: false,
      baseUrl: window.serverConfig.VUE_APP_ADMINURL,
      paramsData: null,
      livePreviewImages: [
        require('../static/images/live-01.jpg'),
        require('../static/images/live-02.jpg')
      ]
    };
  },
  methods: {
    // 验证是否开通直播
    getOpenStatus() {
      checkOpenStatus()
        .then((res) => {
          const { data } = res;
          if (!data.is_open) {
            this.isOpen = data.is_open;
            this.loading = false;
            return;
          }
          console.log("this.tier", this.timer);
          if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
          }
          this.isOpen = data.is_open;
          this.getDetailList(this.filterForm);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 获取品牌列表
    getDetailList(pageData) {
      // this.tbody = [];
      this.pageData = {};
      this.loading = true;
      detailList(pageData)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.pageData = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 开通直播
    handleOpenLive() {
      this.loading = true;
      this.updateLoading = true;
      openLive()
        .then((res) => {
          this.$message.success(res.msg);
          if (!this.timer) {
            this.timer = setInterval(() => {
              this.getOpenStatus();
            }, 3000);
          }
          this.updateLoading = false;
        })
        .catch((err) => {
          this.updateLoading = false;
          this.loading = false;
        });
    },
    // 关闭直播
    handleCloseLive() {
      this.$msgbox
        .confirm("确定关闭 “小程序直播” 功能吗？", "提示", {
          type: "warning",
        })
        .then((Response) => {
          this.loading = true;
          this.updateLoading = true;
          closeLive()
            .then((res) => {
              this.$message.success("关闭成功");
              this.getOpenStatus();
              this.updateLoading = false;
            })
            .catch((err) => {
              this.updateLoading = false;
              this.loading = false;
            });
        })
        .catch((err) => {});
    },
    // 更新直播间数据
    updateLiveList() {
      this.openUpdateLiveDialog = true;
      // this.loading = true;
      // this.updateLoading = true;
      // newLiveList()
      //   .then((res) => {
      //     this.$message.success(res.msg);
      //     this.getOpenStatus();
      //     this.updateLoading = false;
      //   })
      //   .catch((err) => {
      //     this.updateLoading = false;
      //     this.loading = false;
      //   });
    },
    // 更新回放数据
    updateReviewList(id) {
      this.loading = true;
      this.updateLoading = true;
      playbackList({ id })
        .then((res) => {
          this.$message.success(res.msg);
          this.getOpenStatus();
          this.updateLoading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.updateLoading = false;
        });
    },
    // 复制链接
    copyUrl(url) {
      copyText(url).then((valid) => {
        this.$message.success("复制成功");
      });
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
        });
      }
    },
    // 点击下拉菜单
    handleClickRow(value, row) {
      this[value](row);
    },
    // 功能配置
    funcConfig(row) {
      this.currentForm = row;
      this.openFuncConfigDialog = true;
    },
    // 回放视频
    reviewVideo(row) {
      this.currentId = row.room_id;
      this.openReviewVideoDialog = true;
    },
    // 批量删除
    handleDel() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm("确定要将选中数据删除吗？", "提示", {
            type: "error",
          })
          .then((res) => {
            this.handleDelete({ id: this.selectArr });
          })
          .catch((err) => {});
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表删除
    deleteRow(row) {
      this.$msgbox
        .confirm("确定要将此数据删除吗？", "提示", {
          type: "error",
        })
        .then((res) => {
          this.handleDelete({ id: row.id });
        })
        .catch((err) => {});
    },

    // 数据删除
    handleDelete(id) {
      this.loading = true;
      Delete(id)
        .then((res) => {
          this.$message.success(res.msg);
          this.refreshList();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 点击上传图片
    uploadImg(row) {
      this.currentForm = row;
      this.openEditUploadDialog = true;
    },
    // 刷新列表
    refreshList() {
      this.getDetailList({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
    },
    // 修改列表相关状态
    changeStatus(e, key) {
      const data = {};
      data.id = e.id;
      if (key === "sort") {
        data[key] = e[key];
      } else {
        data[key] = e[key] ? 1 : 0;
      }
      this.loading = true;
      if (key == "is_recommend") {
        // 更新推荐状态
        updateRecommend(data)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch(() => {
            this.refreshList();
          });
      } else if (key === "is_show") {
        // 更新显示状态
        updateShow(data)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch(() => {
            this.refreshList();
          });
      } else {
        // 更新排序状态
        updateSort(data)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch(() => {
            this.refreshList();
          });
      }
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getDetailList(this.filterForm);
    },
    // 筛选回调
    ok(e) {
      const pageData = {};
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getDetailList(this.filterForm);
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const data = { ...this.filterForm, ...e };
      this.getDetailList(data);
    },
  },
  created() {
    this.loading = true;
    this.getOpenStatus(); //验证是否开通直播
    getIndexData()
      .then((res) => {
        this.paramsData = res.data.find((el) => el.platform === "mp-weixin");
      })
      .catch((err) => {});
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  components: {
    Pagination,
    liveListFilter,
    EditUploadDialog,
    AddLiveDialog,
    ReviewVideoDialog,
    FuncConfigDialog,
    PopoverInputEdit,
    UpdateLiveListDialog,
  },
};
</script>

<style lang="scss" scoped>
.container {
  .body {
    .err-box {
      ::v-deep.el-input__inner {
        color: red;
      }
    }
  }
}

.preview-area {
  background-color: #dedede;
  padding: $space * 1.5 $space * 5;
  min-width: 0;
  float: left;
  height: 567px;
}

.img-box {
  padding: $space / 2;

  .img {
    height: 100%;
  }
}
.list-image {
  width: 60px;
  height: 60px;
}
.list-btn {
  margin-top: 20px;
}
.url-view {
  display: flex;
  align-items: center;
  .copy {
    color: #3479ef;
    cursor: pointer;
    margin-left: 10px;
  }
  .text-overflow {
    display: inline-block;
    width: 210px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.update-video {
  color: #3479ef;
  margin-left: 10px;
}

.live-tips {
  background: #ebf1ff;
  border: 1px solid #d7e4ff;
  border-radius: 2px;
  margin-bottom: 20px;
  padding: 20px;
  a {
    text-decoration: none;
    color: #3576ff;
  }
  .tips-title {
    color: #3576ff;
    font-size: 18px;
    font-weight: 400;
    display: flex;
    align-items: center;
  }
  .tips-content {
    margin-left: 32px;
    .item-tip {
      font-size: 14px;
      line-height: 24px;
    }
  }
}
</style>
