<template>
  <el-dialog title="功能配置" :visible.sync="openDialog" width="350px" class="dialog-vertical"
    @close="cancel('editForm')" @open="dialogOpen">
    <el-form size="medium" :model="editForm" label-position="right" label-width="120px" ref="editForm">
      <el-form-item label="直播间官方收录：" prop="is_feeds_public">
        <el-radio v-model="editForm.is_feeds_public" :label="1">开启</el-radio>
        <el-radio v-model="editForm.is_feeds_public" :label="0">关闭</el-radio>
      </el-form-item>
      <el-form-item label="直播间全局禁言：" prop="close_comment">
        <el-radio v-model="editForm.close_comment" :label="1">开启</el-radio>
        <el-radio v-model="editForm.close_comment" :label="0">关闭</el-radio>
      </el-form-item>
      <el-form-item label="直播间回放：" prop="close_replay">
        <el-radio v-model="editForm.close_replay" :label="0">开启</el-radio>
        <el-radio v-model="editForm.close_replay" :label="1">关闭</el-radio>
      </el-form-item>
      <el-form-item style="margin-bottom: 0px" label="客服功能：" prop="close_kf">
        <el-radio v-model="editForm.close_kf" :label="0">开启</el-radio>
        <el-radio v-model="editForm.close_kf" :label="1">关闭</el-radio>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="small" @click="openDialog = false">取消</el-button>
      <el-button size="small" type="primary" @click="submit('editForm')" :loading="saveLoading">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { saveConfig } from "../api/live-list.js";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    Form: {
      type: Object,
      default: () => {},
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      title: "",
      saveLoading: false,
      editForm: {
        id: 0,
        is_feeds_public: 0,
        close_comment: 0,
        close_replay: 0,
        close_kf: 0,
      },
    };
  },
  methods: {
    dialogOpen() {
      this.editForm = { ...this.editForm, ...this.Form };
    },
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          const { is_feeds_public, close_comment, close_replay, close_kf, id } =
            this.editForm;
          const data = {
            is_feeds_public,
            close_comment,
            close_replay,
            close_kf,
            id,
          };
          saveConfig(data)
            .then((res) => {
              this.$message.success(res.msg);
              this.updateList();
              this.openDialog = false;
              this.saveLoading = false;
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.$refs[formName].resetFields();
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-vertical {
  ::v-deep.el-form-item__label {
    padding-bottom: 0px;
  }
}

.img-tips {
  text-align: center;
  font-size: 12px;
  color: #c0c4cc;
  line-height: 24px;
}
</style>
